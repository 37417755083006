<template>
  <nav class="flex items-center justify-between flex-wrap bg-white">
    <span
      class="p-2"
      v-if="!inIframe || ($route.name !== 'chat' || ($route.name === 'chat' && store.state.chatUIState !== 2))"
    >
      <span
        v-if="loggedInUser && loggedInUser.name !== 'Anonymous'"
        class="mr-1.5"
      >
        Hi {{ loggedInUser.name }}, welcome to the
      </span>{{ business ? business.name : '' }} Customer Portal
    </span>
    <slot />
  </nav>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'

const inIframe = ref(false)

const $route = useRoute()

const loggedInUser = computed(() => usePortalStore().state.loggedInUser)
const business = computed(() => usePortalStore().state.business)
</script>
