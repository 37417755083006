<!--
NOTES:
- Align prod rows quantities and totals
- prices align right
- only show headers for first row
- only show inline editing if logged in

-->
<template>
  <component
    is="DumbWrapper"
    :css="{ height: inIframe ? '' : '100rvh' }"
    class="bg-main-backgroud w-full flex flex-col"
    :class="[
      $route.name,
      { 'overflow-y-hidden print:overflow-y-auto': status !== 'PAID' },
      { 'inIframe h-full': inIframe }
    ]"
  >
    <LayoutsHeader :in-iframe="inIframe" />

    <div class="flex flex-grow w-full justify-center max-w-full bg-main-backgroud overflow-hidden">
      <div
        v-if="isLoggedIn || !inIframe"
        class="flex flex-col overflow-hidden md:min-w-[100px] bg-white transition-all duration-400 md:border-r md:border-gray-400"
        :class="chatUIState === 0 ? 'min-w-full' : 'w-0'"
      >
        <template v-for="nav in navigationElements">
          <a
            v-if="nav.route"
            :key="nav.route"
            class="w-full h-[100px] items-center justify-center flex cursor-pointer hover:bg-blue-200"
            :class="[
              {
                'bg-gray-100': (
                  ($route.name === 'chat' && nav.route === 'chat') ||
                  ($route.path.includes('/i/') && nav.route.includes('i/')) ||
                  ($route.path.includes('/invoices') && nav.route === 'invoices')
                )
              }
            ]"
            @click="goToNav(nav)"
          >
            <div class="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                aria-hidden="true"
                role="img"
                class="iconify iconify--mdi"
                width="32"
                height="32"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  :d="nav.icon"
                />
              </svg>
              <small>{{ nav.tooltip }}</small>
            </div>
          </a>
          <div
            v-else
            :key="nav.tooltip"
            class="w-full h-[100px] items-center justify-center flex cursor-pointer hover:bg-gray-100"
            activeClass="bg-gray-100"
            @click="nav.click"
          >
            <div class="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                aria-hidden="true"
                role="img"
                class="iconify iconify--mdi"
                width="32"
                height="32"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  :d="nav.icon"
                />
              </svg>
              <small>{{ nav.tooltip }}</small>
            </div>
          </div>
        </template>
      </div>
      <div class="flex w-full md:flex-grow bg-gray-100">
        <Nuxt />
      </div>
    </div>
    <transition name="fade">
      <LoginPanel
        v-show="showSignin"
        ref="login"
        :inline="false"
        @close="showSignin = false"
      />
    </transition>
  </component>
</template>

<script>
import rest from '~/utils/rest'

export default {
  data() {
    return {
      login: false,
      showSignin: false,
      inIframe: false
    }
  },
  head() {
    return {
      bodyAttrs: {
        class: this.$route.name
      }
    }
  },
  computed: {
    chatUIState: {
      get() {
        return this.$store.state.chatUIState
      },
      set(newVal) {
        this.$store.commit('SET_CHAT_UI_STATE', newVal)
      }
    },
    businessCode() {
      return this.$store.getters.getBusinessCode
    },
    invoiceCode() {
      return this.$store.getters.getInvoiceCode
    },
    username() {
      return this.$store.getters.getUsername
    },
    loggedInUser() {
      return this.$store.getters.getLoggedInUser
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    invoice() {
      return this.$store.state.invoice
    },
    status() {
      return getInvoiceStatus(this.invoice)
    },
    navigationElements() {
      if (!this.isLoggedIn) {
        return [
          {
            icon: 'M18 8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h9V6a3 3 0 0 0-3-3a3 3 0 0 0-3 3H7a5 5 0 0 1 5-5a5 5 0 0 1 5 5v2h1m-6 9a2 2 0 0 0 2-2a2 2 0 0 0-2-2a2 2 0 0 0-2 2a2 2 0 0 0 2 2Z',
            route: '',
            tooltip: 'Unlock',
            click: () => {
              this.$store.commit('SET_USERNAME', '')
              /*
              if (this.$refs.login) {
                this.$refs.login.password = ''
              }
              */
              this.showSignin = true
            }
          }
        ]
      } else {
        const buttons = [
          {
            icon: 'M12 3C6.5 3 2 6.58 2 11a7.218 7.218 0 0 0 2.75 5.5c0 .6-.42 2.17-2.75 4.5c2.37-.11 4.64-1 6.47-2.5c1.14.33 2.34.5 3.53.5c5.5 0 10-3.58 10-8s-4.5-8-10-8m0 14c-4.42 0-8-2.69-8-6s3.58-6 8-6s8 2.69 8 6s-3.58 6-8 6Z',
            route: 'chat',
            tooltip: 'Chat'
          }
        ]

        if (this.loggedInUser.level >= 10) {
          buttons.push(
            {
              icon: 'M3 9h14V7H3v2m0 4h14v-2H3v2m0 4h14v-2H3v2m16 0h2v-2h-2v2m0-10v2h2V7h-2m0 6h2v-2h-2v2Z',
              route: 'invoices',
              tooltip: 'Invoice list'
            }
          )
          /*
          buttons.push(
            {
              icon: 'M3 9h14V7H3v2m0 4h14v-2H3v2m0 4h14v-2H3v2m16 0h2v-2h-2v2m0-10v2h2V7h-2m0 6h2v-2h-2v2Z',
              route: 'checkouts',
              tooltip: 'Checkout list'
            }
          )
          */
        }

        if (this.invoiceCode) {
          buttons.push({
            icon: 'M14 10h5.5L14 4.5V10M5 3h10l6 6v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.11.89-2 2-2m0 9v2h14v-2H5m0 4v2h9v-2H5Z',
            route: `i/${this.businessCode}/${this.invoiceCode}`,
            tooltip: 'Invoice'
          })
        }

        return buttons
      }
    }
  },
  mounted() {
    rest.setDomainRoot()
    if (window.self !== window.top) {
      this.inIframe = true
    } else if (!this.loggedInUser) {
      const username = `anonymous@${this.$route.params.businessCode}`
      this.$store.commit('SET_USERNAME', username)
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs?.login?.signIn(username)
            .then(() => {
              this.$store.commit('SET_BUSINESS_CODE', this.$route.params.businessCode)
            })
            .catch(err => console.error(err))
        })
      }, 100)
    }
  },
  methods: {
    goToNav(nav) {
      this.chatUIState = 1
      if (!this.$route.path.includes(nav.route)) {
        this.$router.replace('/' + nav.route)
      }
    }
  }
}

</script>
